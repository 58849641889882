<template>
  <div class="">
    <div>
      <p>Veuillez remplir les informations ci-dessous pour créer une nouvelle souscription :</p>
    </div>
    <form @submit.prevent="submitSubscription">
      <div class="row">
        <div class="col-xl-3 my-3">
          <label for="price"> Client</label>
          <input type="text" :value="customer.fullName" :disabled="true" class="form-control">
        </div>

        <div class="col-xl-3 my-3">
          <label for="price"> Pack </label>
          <select v-model="subscription.pricing_reference" @change="updateSelectedPricing" class="form-select">
            <option value="" disabled selected>Choisissez un pack...</option>
            <option v-for="(pricing, index) in pricings" :key="index" :value="pricing.reference">
              {{pricing.fullName}} / {{pricing.hours}} heures / {{ pricing.priceTotal }}
            </option>
          </select>  
        </div>

        <div class="col-xl-3 my-3">
          <label for="price">Hours</label>
          <input type="text" :disabled="true" :value="subscription.hours" class="form-control">
        </div>

        <div class="col-xl-3 my-3">
          <label for="price">Price</label>
          <input type="text" :disabled="true" :value="subscription.price" class="form-control">
        </div>

        <div class="col-xl-3 my-3">
          <label for="price"> Montant à payer </label>
          <input type="text" v-model="subscription.amountToPay" class="form-control">
        </div>

        <div class="col-xl-3 my-3">
          <label for="price"> Remise </label>
          <input type="text" v-model="subscription.discount" disabled class="form-control">
        </div>

        <div v-if="subscription.discount>0" class="col-xl-6 my-3">
          <label for="price"> Motif de remise </label>
          <input type="text" v-model="subscription.reasonDiscount" class="form-control">
        </div>
      </div>
      <button type="submit" class="btn btn-primary mt-3">
        Create subscription
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      subscription: {
        pricing_reference: '',
        hours: '',
        price: '',
        amountToPay: '',
        discount: '',
        reasonDiscount: ''
      },
      selectedPricing: {
        hours: '',
        priceTotal: ''
      }
    };
  },
  async mounted() {
    if (!this.$route.params.reference) {
      await this.$router.push({ name: 'customers-index' });
    }
    await this.$store.dispatch("customer/show", this.$route.params.reference); 
    await this.$store.dispatch("subscription/getAll");
    await this.$store.dispatch("customer/getAll");
    await this.$store.dispatch("pricing/getAll");
  },
  methods: {
    async submitSubscription() {
      await (this.subscription.field_reference = this.$route.params.reference)
      await this.$store.dispatch("subscription/store", this.subscription);
    },
    updateSelectedPricing() {
      // Find the selected pricing based on the reference
      const pricing = this.pricings.find(p => p.reference === this.subscription.pricing_reference);
      if (pricing) {
        this.subscription.hours = pricing.hours;
        this.subscription.price = pricing.priceTotal;
        this.subscription.amountToPay = pricing.priceTotal;  // Set the default to total price
        this.subscription.discount = 0;  // Reset discount initially
      }
    }
  },
  watch: {
    'subscription.amountToPay'(newVal) {
      // Calculate the discount based on the difference between total price and amount to pay
      const totalPrice = parseFloat(this.subscription.price);
      const amountToPay = parseFloat(newVal);

      if (!isNaN(totalPrice) && !isNaN(amountToPay)) {
        this.subscription.discount = totalPrice - amountToPay;
      }
    }
  },
  computed: {
    ...mapGetters('subscription', {
      subscriptions: 'getSubscriptions',
    }),
    ...mapGetters("customer", {
      customers: "getCustomers",
      customer: "getCustomer",
    }),
    ...mapGetters('pricing', {
      pricings: 'getPricings',
    })
  }
};
</script>

<style scoped>
</style>
